import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="easyCoffee Customer Story - Rotaready"
          description="This is how easyCoffee moved from pen and paper to real-time wage control across their estate in only a fortnight."
          url="customer-stories/hospitality-easycoffee"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Barista handing a coffee to a customer"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="easyCoffee logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="This is how easyCoffee moved from pen and paper to real-time wage control across their estate in only a fortnight." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="6" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="100+" />
              <StatSubtitle text="staff" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="easyCoffee" />
              </Content>

              <Content>
                <TextBlock text="A growing café franchise with a number of sites across the UK, easyCoffee prides itself on not only providing the best possible customer service and engaging with the local community but looking after its staff too." />
              </Content>

              <Content>
                <TextBlock text="Part of making sure staff are happy is ensuring they are being paid correctly and things such as holiday allowance and overtime are monitored accurately. Sounds simple, right?" />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A painful approach to payroll" />
              </Content>

              <Content>
                <TextBlock text="Wrong. As the group were using spreadsheets to keep track of staff time they were finding it increasingly difficult to accurately monitor holiday accrual and hours worked." />
              </Content>

              <Content>
                <TextBlock text="As if the dated approach to tracking staff time and attendance wasn't causing enough of a headache, how staff clocked in and out varied from store to store. Some were signing in and out on a paper chart and others were using a machine that printed the time they started or finished on a clocking card. The combination of these factors meant keeping control over staff costs and ensuring accurate payroll was proving tricky to say the least." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="A time for change" />
              </Content>

              <Content>
                <TextBlock text="easyCoffee decided something needed to change so went about finding a solution that would enable them to monitor staff time with greater accuracy and give them more control over staff costs. After extensive research they identified Rotaready as the best supplier to help them tackle the challenges they were facing." />
              </Content>

              <Content>
                <Blockquote
                  text="Rotaready's pricing was very reasonable given the results we knew we would achieve from using it. It had all of the features we needed to achieve greater cost control, improve our payroll and help the business move forward."
                  attribution="Vivian Peng - Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="The partnership between easyCoffee and Rotaready was seamless from the get go, with all 6 sites making the move from dated spreadsheets to intelligent rota software within 2 weeks." />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Implementation across all sites was very simple. The team at Rotarerady were always on hand to help and willing to go out of their way for us; making the move from pen and paper a quick and painless process."
                  attribution="Vivian Peng - Operations Manager"
                />
              </Content>

              <Content>
                <TextBlock text="easyCoffee immediately saw great results from using Rotaready. In just 6 months of using the software the business saw a 10% reduction in both staff hours and wage spend as a result of more accurate time management. Implementing Rotaready made it easier than ever for easyCoffee to monitor staff time and attendance and subsequently ensure accurate payroll." />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Fresh croissants"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Looking to the future" />
              </Content>

              <Content>
                <TextBlock text="Having started out using Rotaready to calculate and track holiday accrual, schedule staff rotas, monitor time and attendance and export this data to inform payroll; easyCoffee are now working closely with Rotaready to get even more from its software." />
              </Content>

              <Content>
                <TextBlock text="6 months on, Rotaready are working with easyCoffee to roll out a bespoke integration with its payroll provider. This will make the payroll process even simpler." />
              </Content>

              <Content>
                <TextBlock text="It doesn't stop there. The Rotaready team will continue to work closely with the team at easyCoffee to ensure they are getting the most out of every feature as the franchise grows. Next on the agenda is an integration with their EPOS provider to help them monitor sales with greater accuracy and make even more informed scheduling decisions in the future." />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_easycoffee"
                label="rotaready.com/customer-stories/hospitality-easycoffee"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-easyCoffee.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-easycoffee.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-easycoffee.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-easycoffee.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
